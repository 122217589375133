table:not(.has-footer) .mdc-data-table__row:last-child .mat-mdc-cell {
  // override "border-bottom: none"
  border-bottom-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12)) !important;
  border-bottom-width: var(--mat-table-row-item-outline-width, 1px) !important;
  border-bottom-style: solid !important;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  &.no-padding {
    padding: 0 !important;
  }
}