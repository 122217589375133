@use '/node_modules/@angular/material/core/style/variables' as v;

////
//// break points for when a content pane should stop expanding horizontally
////
$max-content:    1024px;

$break-small:    600px;
$break-medium:   750px;
$break-tablet:   768px;
$break-laptop:   1024px;
$break-1280:     1280px;
$break-laptop-L: 1440px;
$break-4k:       2560px;


////
//// break point classes
////
@mixin hide-below($breakpoint) {
  @media (max-width: $breakpoint) {
    display: none !important;
  }
}
.hide-below-small    { @include hide-below($break-small); }
.hide-below-medium   { @include hide-below($break-medium); }
.hide-below-tablet   { @include hide-below($break-tablet); }
.hide-below-laptop   { @include hide-below($break-laptop); }
.hide-below-1280     { @include hide-below($break-1280); }
.hide-below-laptop-L { @include hide-below($break-laptop-L); }
.hide-below-4k       { @include hide-below($break-4k); }

@mixin hide-above($breakpoint) {
  // plus 1px to avoid a width were hide-below and hide-above are both active
  @media (min-width: calc($breakpoint + 1px)) {
    display: none !important;
  }
}
.hide-above-small    { @include hide-above($break-small); }
.hide-above-medium   { @include hide-above($break-medium); }
.hide-above-tablet   { @include hide-above($break-tablet); }
.hide-above-laptop   { @include hide-above($break-laptop); }
.hide-above-1280     { @include hide-above($break-1280); }
.hide-above-laptop-L { @include hide-above($break-laptop-L); }
.hide-above-4k       { @include hide-above($break-4k); }



@mixin height-100vh-minus-toolbar ($additional-offset: 0px) {
  height: calc(100vh - 64px - $additional-offset);
  @media (#{v.$xsmall}) { height: calc(100vh - 56px - $additional-offset); }
}

@mixin min-height-100vh-minus-toolbar ($additional-offset: 0px) {
  min-height: calc(100vh - 64px - $additional-offset);
  @media (#{v.$xsmall}) { min-height: calc(100vh - 56px - $additional-offset); }
}

@mixin max-height-100vh-minus-toolbar ($additional-offset: 0px) {
  max-height: calc(100vh - 64px - $additional-offset);
  @media (#{v.$xsmall}) { max-height: calc(100vh - 56px - $additional-offset); }
}
