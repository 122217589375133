@use '@angular/material' as mat;
@use 'sass:map';

$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-primary-contrast: $dark-text;
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-accent-contrast: $dark-text;
$dark-warn-text: rgba($dark-primary-text, 0.54);
$dark-warn-contrast: $dark-text;
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-primary-contrast: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-accent-contrast: $light-text;
$light-warn-text: rgba($light-primary-text, 0.7);
$light-warn-contrast: $light-text;
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

:root {
  --warn-color:              #{map.get(mat.$red-palette, 500)};
  --warn-lighter-color:      #{map.get(mat.$red-palette, 100)};
  --warn-darker-color:       #{map.get(mat.$red-palette, 500)};
  --text-warn-color:         #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color:  #{$light-primary-text};
}
$warn-palette: mat.define-palette(mat.$red-palette);