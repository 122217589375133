@use 'material-icons/iconfont/material-icons.scss' as mat_icon;

.mdc-list-item__secondary-text {
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-weight: 400;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start {
  opacity: var(--mdc-list-list-item-disabled-label-text-opacity) !important;
  --mdc-list-list-item-hover-leading-icon-color: var(--mdc-list-list-item-disabled-leading-icon-color)
}


.mat-mdc-list-item.mdc-list-item--with-two-lines,
.mat-mdc-list-item.mdc-list-item--with-two-lines.mdc-list-item--with-leading-icon {
  &.dynamic-height {
    min-height: 56px;
    height: unset !important;

    .mdc-list-item__start {
      align-self: center !important;
      margin-top: 0 !important;
    }

    .mdc-list-item__secondary-text {
      padding-bottom: 15px;
      span {
        white-space: normal;
      }
    }

    .mdc-list-item__end {
      &.mat-icon {
        // include material icon class
        @include mat_icon.material-icons-font-class('Material Icons');
        align-self: center !important;

        &::before {
          display: none
        }
      }

      &:not(.mat-icon) {
        align-self: center !important;

        &::before {
          display: none
        }
      }
    }
  }
}