$notifMaxWidth: 370px;
$notifMargin: 10px;

$notifBreakPoint: 2*$notifMaxWidth + 4*$notifMargin;

$notifBorderWidth: 1px;
$notifBorder: $notifBorderWidth solid #5b567e;
$notifBG: rgba(44, 44, 44, 0.93);
$notifBG_fallback: rgba(58, 58, 58);
$notifColor: #E7E6E5;

$notifPadding: 7px;

$buttonHeight: 30px;

$tt: 0.3s;
$tth: 0.15s;
// $quickTransitionTime: 0.2s;

#notification-container {
  position: fixed;
  top: 0;
  z-index: 10000;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;


  // .corner {
  //   background: yellow;
  //   .wrapper {
  //     background: red;
  //     .stack {
  //       background: green;
  //     }
  //   }
  // }



  ////
  //// large screen
  ////
  @media (min-width: $notifBreakPoint) {
    width: 100%;
    overflow: hidden !important;
  }

  ////
  //// small screen (remove one pixel of slack)
  ////
  @media (max-width: ($notifBreakPoint - 1px)) and (min-width: $notifMaxWidth) {
    width: $notifMaxWidth;
    right: 0;
    overflow-x: hidden !important;
    overflow-y: overlay;
  }

  ////
  //// really small screen (remove one pixel of slack)
  ////
  @media (max-width: ($notifMaxWidth - 1px)) {
    width: 100%;
    overflow-x: hidden !important;
    overflow-y: overlay;
  }



  .corner {
    ////
    //// large screen
    ////

    // to make scrollable
    pointer-events: auto;

    @media (min-width: $notifBreakPoint) {
      position: absolute;
      width: $notifMaxWidth;
      display: flex;
      max-height: 100%;
      overflow-x: hidden !important;
      overflow-y: overlay; // the emulated scrollbar must always be present

      padding-left: 10px;
      padding-right: 10px;
      // padding-right: 2px; // due to the emulated scroll-bar with being 8px


      transition: transform $tt ease, opacity $tt ease;
      box-sizing: border-box;

      z-index: 0;
      &:hover {
        z-index: 1;
        // padding-top: 10px;
        // padding-bottom: 10px;

        &.top .wrapper {
          margin-bottom: 10px;
        }
      }

      &.transparent {
        opacity: 0.5;
      }

      &.top {
        // top: $notifMargin;
        top: 0;
        padding-top: 10px;
        flex-flow: column;

        // .stack:last-child {
        //   margin-bottom: 0;
        // }
      }
      &.bottom {
        // bottom: $notifMargin;
        bottom: 0;
        padding-bottom: 10px;
        flex-flow: column-reverse;

        // .stack:first-child {
        //   margin-top: 0;
        // }
      }
      &.left {
        // left: $notifMargin;
        left: 0;

        &.transparent {
          transform: translate(-$notifMaxWidth - 20px, 0);
        }

        .notification.closing {
          left: -120%;
          right: 120%;
        }
      }
      &.right {
        // right: $notifMargin;
        right: 0;

        &.transparent {
          transform: translate($notifMaxWidth + 20px, 0);
        }

        .notification.closing {
          left: 120%;     // OBS
          right: -120%;
        }
      }

      &.top .stack {
        margin-bottom: 10px;

        // &:hover {
        //   // margin-bottom: 3px;
        //   margin-bottom: 10px;
        // }
      }

      &.bottom .stack {
        margin-top: 10px;

        &:hover {
          // margin-bottom: -7px;
          .notification:last-child {
            // background: orange !important;
            margin-bottom: 0;
          }
        }
      }

    }

    ////
    //// small screen (remove one pixel of slack)
    ////
    @media (max-width: ($notifBreakPoint - 1px)) {
      float: none;
      margin-left: $notifMargin;
      margin-right: $notifMargin;
      overflow: visible !important;

      &:first-child {
        margin-top: 10px;
      }

      .stack {
        margin-bottom: 10px;

        &:hover {
          // margin-bottom: 3px;
          margin-bottom: 10px;
        }
      }

      .notification.closing {
        left: 120% !important;
        right: -120% !important;
      }

    }


    .stack {
      // to prevent margin colapse
      display: flow-root;

      transition: margin $tt ease;
      transition-delay: 0.2s;

      // remove any margin
      &.closing {
        margin: 0;
        transition-delay: 0;
      }

      .notification {
        position: relative;
        border-radius: 8px;

        border: $notifBorder;
        box-sizing: border-box;

        color: $notifColor;
        box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 79%);

        cursor: default;

        left: 0;
        right: 0;


        ////
        //// close and create
        ////
        &.creating {
          opacity: 0 !important;

          // to supress the border width (OBS TOP)
          margin-top: -2*$notifBorderWidth !important;
        }

        &.closing {
          // opacity: 0;

          // to supress the border width (OBS BOTTOM)
          margin-bottom: -2*$notifBorderWidth !important;
        }

        &.creating, &.closing {
          &, .content, .title, .body {
            transition-delay: 0s !important;
          }

          .content {
            overflow: hidden !important;
            max-height: 0px !important;
          }
        }

        ////
        //// animate margins to match the new class
        //// (split in two as to not affect stacks lower down)
        ////
        &.after-closing-first {
          transition: margin $tth ease !important;
        }
        &.after-closing-second {
          margin-top: 0 !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        .content {
          overflow: hidden;
          max-height: 300px;
        }



        // if backdrop support: very transparent and blurred
        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
          background: $notifBG;
          -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
        }

        // fallback
        background-color: $notifBG_fallback;

        // anitmate
        &, .content, .title, .body {
          transition: all $tt ease;
          transition-delay: 0.2s;
        }


        &:hover {
          z-index: 10 !important;
        }

        &:nth-child(1) {
          z-index: 4;
        }

        &:nth-child(n+2) {

          margin-top: -3px;

          .title /*, .buttons */ {
            padding: 0 $notifPadding;
            opacity: 0;
            max-height: 0;
          }
        }

        &:nth-child(2) {
          margin-left: 1px;
          margin-right: 1px;
          z-index: 3;
        }

        &:nth-child(n+3) {
          margin-top: -5px;

          .content {
            max-height: 10px;
          }

          .body {
            opacity: 0;
          }
        }

        &:nth-child(3) {
          margin-left: 4px;
          margin-right: 4px;
          z-index: 2;
        }

        &:nth-child(4) {
          margin-left: 8px;
          margin-right: 8px;
          z-index: 1;
        }

        &:nth-child(n+5) {
          margin-left: 8px;
          margin-right: 8px;
          visibility: hidden;
          opacity: 0;
          margin-top: -12px;
        }


        ////
        //// contents
        ////
        .title {
          padding: $notifPadding $notifPadding 0 $notifPadding;
          overflow: hidden;

          .icon {
            font-size: 1.15em;
            vertical-align: text-top;
          }
        }

        .body {
          padding: $notifPadding;
          overflow: hidden;

          // needed for child to claim 100% height
          display: flex;

          font-size: 0.9em;
          font-weight: lighter;
        }

        .buttons-wrapper {
          float: right;
          padding: 0 0 0 $notifPadding;

          // take all the height
          height: 100%;

          // push the image to the bottom
          display: flex;
          align-items: flex-end;

          // make the text flow on the top free space
          shape-outside: inset(calc(100% - #{$buttonHeight}) 0 0);

          button {
            height: $buttonHeight;
            border-radius: 6px;
            border: none;
            padding: 6px 10px;
            cursor: pointer;
            background: none;
            color: $notifColor;
            font-size: 0.9em;

            transition: background $tt ease;

            &:hover {
              background: rgb(255 255 255 / 05%);
            }

            &:nth-child(n+2) {
              margin-left: 5px;
            }
          }
        }

        .bottom-pusher {
          height: calc(1em - #{$notifPadding});
        }

        div.close {
          width: 18px;
          height: 18px;
          border-radius: 9px;
          position: absolute;
          top: -7px;
          left: -7px;
          cursor: pointer;

          border: $notifBorder;
          box-sizing: border-box;
          background: $notifBG;

          opacity: 0;
          transition: opacity $tt ease;

          &:before, &:after {
            content: "";
            position: absolute;
            top: 7px;
            left: 1px;
            width: 10px;
            height: 1px;
            background: $notifColor;
            top: 7px;
            left: 3px;
          }
          &:before {
            transform: translate(0, 0.5px) rotate(45deg);
          }
          &:after {
            transform: translate(0, 0.5px) rotate(-45deg);
          }

        }
        &:hover div.close {
          opacity: 1;
          transition-delay: 0.2s;
        }

      }


      &:hover {

        // anitmate
        &, .notification, .content, .title, .body {
          transition-delay: 0s;
        }

        .notification {
          opacity: 1;
          visibility: visible;

          margin: 0 0 7px 0;
          &:last-child {
            margin-bottom: 0;
          }

          .content {
            max-height: 300px;
            // overflow: scroll;
            overflow-y: auto;
            overflow-x: hidden;
          }

          .title {
            padding: $notifPadding $notifPadding 0 $notifPadding;
            opacity: 1;
            max-height: 300px;
          }


          .body {
            opacity: 1;
          }
        }
      }

    }

  }

}



#notification-container .notification {
  span.chip {
    border-radius: 4px;
    padding-left: 2px;
    padding-right: 2px;
  }
}
