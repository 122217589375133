@use '@angular/material' as mat;
@use 'index'             as shared;
@use 'typography'        as typography;

// Theme Config
.konecto-theme {
  --primary-color: #E23467;
  --primary-lighter-color: #E44474;
  --primary-darker-color: #DB1F57;
  --text-primary-color: #{shared.$light-primary-text};
  --text-primary-lighter-color: #{shared.$light-primary-text};
  --text-primary-darker-color: #{shared.$light-primary-text};
}
$mat-primary: (
  main: var(--primary-color),
  lighter: var(--primary-lighter-color),
  darker: var(--primary-darker-color),
  50: #ED82A2,
  100: #EC799C,
  200: #E9678E,
  300: #E75581,
  400: #E44474,
  500: #E23467,
  600: #DB1F57,
  700: #C01B4D,
  800: #A51842,
  900: #8F1439,
  contrast: (
    main: shared.$light-primary-contrast,
    lighter: shared.$light-primary-contrast,
    darker: shared.$light-primary-contrast,
    50: shared.$light-primary-contrast,
    100: shared.$light-primary-contrast,
    200: shared.$light-primary-contrast,
    300: shared.$light-primary-contrast,
    400: shared.$light-primary-contrast,
    500: shared.$light-primary-contrast,
    600: shared.$light-primary-contrast,
    700: shared.$light-primary-contrast,
    800: shared.$light-primary-contrast,
    900: shared.$light-primary-contrast
  )
);
$theme-primary: mat.define-palette($mat-primary);

.konecto-theme {
  --accent-color: #2780E3;
  --accent-lighter-color: #4F96E8;
  --accent-darker-color: #1C72D4;
  --text-accent-color: #{shared.$dark-accent-text};
  --text-accent-lighter-color: #{shared.$dark-accent-text};
  --text-accent-darker-color: #{shared.$dark-accent-text};
}
$mat-accent: (
  main: var(--accent-color),
  lighter: var(--accent-lighter-color),
  darker: var(--accent-darker-color),
  50: #81B4EF,
  100: #78AFED,
  200: #61A1EA,
  300: #4F96E8,
  400: #3D8CE6,
  500: #2780E3,
  600: #1C72D4,
  700: #1966BE,
  800: #1557A2,
  900: #124B8C,
  contrast: (
    main: shared.$light-accent-contrast,
    lighter: shared.$light-accent-contrast,
    darker: shared.$light-accent-contrast,
    50: shared.$light-accent-contrast,
    100: shared.$light-accent-contrast,
    200: shared.$light-accent-contrast,
    300: shared.$light-accent-contrast,
    400: shared.$light-accent-contrast,
    500: shared.$light-accent-contrast,
    600: shared.$light-accent-contrast,
    700: shared.$light-accent-contrast,
    800: shared.$light-accent-contrast,
    900: shared.$light-accent-contrast
  )
);
$theme-accent: mat.define-palette($mat-accent);

.konecto-theme {
  --gradient-color: linear-gradient(to right, var(--accent-color), var(--primary-color));
}

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent:  $theme-accent,
    warn:    shared.$warn-palette
  ),
  typography: typography.$typography,
  density: 0,
));