@use 'scrollbar.scss' as scrollbar;
@use 'variables.scss' as variables;


.mat-mdc-dialog-container .mdc-dialog__title {
  display: flex;
  align-items: center;
  min-height: 58px;
  margin-bottom: 0;
  border-bottom: 1px solid variables.$royal-border-color;
  background-color: #fefefe;
  padding: 9px 24px;

  --mdc-dialog-subhead-color: #0D0D0D;
  --mdc-dialog-subhead-size: 16px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.07em;
  &:is(h1), & > h1 {
    color: var(--mdc-dialog-subhead-color);
    font-size: var(--mdc-dialog-subhead-size);
    font-weight: var(--mdc-dialog-subhead-weight);
    letter-spacing: var(--mdc-dialog-subhead-tracking);

    text-transform: uppercase;
  }
  & > h1 {
    margin: 0;
  }

  &::before {
    display: none;
  }
}

.responsive-dialog {
  @media (max-width: 640px) {
    max-width: unset !important;
    width: 100vw !important;
    height: 100vh !important;
    .mat-mdc-dialog-surface {
      border-radius: 0 !important;
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__content.overflow-hidden {
  overflow: hidden;
}

.mat-mdc-dialog-container .mdc-dialog__content:not(.overflow-hidden) {
  @include scrollbar.dark-scrollbar;
}
.mat-mdc-dialog-container .mdc-dialog__title + .mdc-dialog__content {
  &:not(.no-padding) {
    padding-top: 20px;
  }
  &.no-padding {
    padding: 0;
  }
}

.mat-mdc-dialog-container .mdc-dialog__actions {
  border-top: 1px solid variables.$royal-border-color;
  background-color: #fefefe;
}