@use '@angular/material' as mat;
@use 'index'             as shared;
@use 'typography'        as typography;

// Theme Config
.default-theme {
  --primary-color: #9776f9;
  --primary-lighter-color: #e0d6fd;
  --primary-darker-color: #7a59f6;
  --text-primary-color: #{shared.$light-primary-text};
  --text-primary-lighter-color: #{shared.$light-primary-text};
  --text-primary-darker-color: #{shared.$light-primary-text};
}
$mat-primary: (
  main: var(--primary-color),
  lighter: var(--primary-lighter-color),
  darker: var(--primary-darker-color),
  50: #BCA7FB,
  100: #B8A2FB,
  200: #B198FB,
  300: #A68AFA,
  400: #9E80F9,
  500: #9776F9,
  600: #6E40F7,
  700: #450BF4,
  800: #3508BA,
  900: #250684,
  contrast : (
    main: shared.$light-primary-contrast,
    lighter: shared.$light-primary-contrast,
    darker: shared.$light-primary-contrast,
    50 : shared.$light-primary-contrast,
    100 : shared.$light-primary-contrast,
    200 : shared.$light-primary-contrast,
    300 : shared.$light-primary-contrast,
    400 : shared.$light-primary-contrast,
    500 : shared.$light-primary-contrast,
    600 : shared.$light-primary-contrast,
    700 : shared.$light-primary-contrast,
    800 : shared.$light-primary-contrast,
    900 : shared.$light-primary-contrast
  )
);
$theme-primary: mat.define-palette($mat-primary);


.default-theme {
  --accent-color: #6879f9;
  --accent-lighter-color: #d2d7fd;
  --accent-darker-color: #4b5cf6;
  --text-accent-color: #{shared.$light-primary-text};
  --text-accent-lighter-color: #{shared.$dark-primary-text};
  --text-accent-darker-color: #{shared.$light-primary-text};
}
$mat-accent: (
  main: var(--accent-color),
  lighter: var(--accent-lighter-color),
  darker: var(--accent-darker-color),
  50: #A7B1FC,
  100: #9DA8FB,
  200: #8E9BFB,
  300: #8492FA,
  400: #7685FA,
  500: #6879F9,
  600: #364CF7,
  700: #0924EB,
  800: #071CB6,
  900: #051484,
  contrast : (
    main: shared.$light-accent-contrast,
    lighter: shared.$light-accent-contrast,
    darker: shared.$light-accent-contrast,
    50 : shared.$light-accent-contrast,
    100 : shared.$light-accent-contrast,
    200 : shared.$light-accent-contrast,
    300 : shared.$light-accent-contrast,
    400 : shared.$light-accent-contrast,
    500 : shared.$light-accent-contrast,
    600 : shared.$light-accent-contrast,
    700 : shared.$light-accent-contrast,
    800 : shared.$light-accent-contrast,
    900 : shared.$light-accent-contrast
  )
);
$theme-accent: mat.define-palette($mat-accent);

.default-theme {
  --gradient-color: linear-gradient(to right, var(--accent-color), var(--primary-color));
}

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent:  $theme-accent,
    warn:    shared.$warn-palette
  ),
  typography: typography.$typography,
  density: 0,
));
