
@mixin dark-scrollbar {
  overflow: auto !important;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track, &::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0.1);
    // as to not overflow the border radius
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(0, 0, 0, 0.4);
    -webkit-border-radius: 6px;
    border-radius: 6px;
    min-height: 36px;
  }
}
