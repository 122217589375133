@use '@angular/material' as mat;
@use 'index'             as shared;
@use 'typography'        as typography;

.scs-theme {
  --primary-color: #6c89b0;
  --primary-lighter-color: #7b95b8;
  --primary-darker-color: #4f6c97;
  --text-primary-color: #{shared.$light-primary-text};
  --text-primary-lighter-color: #{shared.$light-primary-text};
  --text-primary-darker-color: #{shared.$light-primary-text};
}
$mat-primary: (
  main: var(--primary-color),
  lighter: var(--primary-lighter-color),
  darker: var(--primary-darker-color),
  50: #BDCADB,
  100: #B6C5D8,
  200: #A2B5CD,
  300: #92A7C4,
  400: #7E98B9,
  500: #6C89B0,
  600: #56759F,
  700: #476285,
  800: #394E6A,
  900: #2B3B50,
  contrast : (
    main: shared.$light-primary-contrast,
    lighter: shared.$light-primary-contrast,
    darker: shared.$light-primary-contrast,
    50 : shared.$light-primary-contrast,
    100 : shared.$light-primary-contrast,
    200 : shared.$light-primary-contrast,
    300 : shared.$light-primary-contrast,
    400 : shared.$light-primary-contrast,
    500 : shared.$light-primary-contrast,
    600 : shared.$light-primary-contrast,
    700 : shared.$light-primary-contrast,
    800 : shared.$light-primary-contrast,
    900 : shared.$light-primary-contrast
  )
);
$theme-primary: mat.define-palette($mat-primary);

.scs-theme {
  --accent-color: #4981CA;
  --accent-lighter-color: #A4C0E5;
  --accent-darker-color: #356DB6;
  --text-accent-color: #{shared.$dark-primary-text};
  --text-accent-lighter-color: #{shared.$dark-primary-text};
  --text-accent-darker-color: #{shared.$dark-primary-text};
}
$mat-accent: (
  main: var(--accent-color),
  lighter: var(--accent-lighter-color),
  darker: var(--accent-darker-color),
  50: #B0C8E8,
  100: #A4C0E5,
  200: #8CB0DE,
  300: #79A2D8,
  400: #6192D1,
  500: #4981CA,
  600: #356DB6,
  700: #2D5C9A,
  800: #24497A,
  900: #1C395F,
  contrast : (
    main: shared.$light-accent-contrast,
    lighter: shared.$light-accent-contrast,
    darker: shared.$light-accent-contrast,
    50 : shared.$light-accent-contrast,
    100 : shared.$light-accent-contrast,
    200 : shared.$light-accent-contrast,
    300 : shared.$light-accent-contrast,
    400 : shared.$light-accent-contrast,
    500 : shared.$light-accent-contrast,
    600 : shared.$light-accent-contrast,
    700 : shared.$light-accent-contrast,
    800 : shared.$light-accent-contrast,
    900 : shared.$light-accent-contrast
  )
);
$theme-accent: mat.define-palette($mat-accent);

.scs-theme {
  --gradient-color: linear-gradient(to right, var(--accent-lighter-color), var(--primary-lighter-color));
}

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent:  $theme-accent,
    warn:    shared.$warn-palette
  ),
  typography: typography.$typography,
  density: 0,
));