

.mdc-button.reversed {
  flex-flow: row-reverse;

  & > .mat-icon {
    margin-right: 0;
    margin-left: 0.5em;
  }
}

.mdc-button.no-gap {
  & > .mat-icon {
    margin-right: 0;
    margin-left: 0;
  }
}

// is this legacy???
// // Handle buttons appropriately, with respect to line-height
// .mat-mdc-raised-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button {
//   padding: 0 1.15em;
//   margin: 0 .65em;
//   min-width: 3em;
//   line-height: 36.4px
// }
