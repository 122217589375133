
.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  --mat-menu-item-label-text-size: 14px;
}

.mat-mdc-menu-panel.no-vertical-padding  {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-menu-panel.transition-all  {
  transition: all 0.4s ease;
}
