// makes the input height 100% of the infix such that the input text becomes centered
// THIS CREATES A PROBLEM FOR CHIP GRIDS
.mat-mdc-form-field-infix {
  display: flex;
}

// the suffix icon should be equally distant from the upper, lower and right border
.mat-mdc-form-field-icon-suffix {
  padding: 0 4px 0 4px !important;
}

.mdc-text-field--disabled {
  .form-field-prefix, .form-field-suffix {
    color: var(--mdc-outlined-text-field-disabled-input-text-color);
  }
}

// ellipsis for too long labels
.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: ellipsis;
}

// date ranges must be slightly wider than the 180px in order to house the "YYYY-MM-DD - YYYY-MM-DD" without overflowing
.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
  min-width: 210px;

  mat-date-range-input {
    line-height: 24px;
  }
}

// otherwise the label is moved down 1px when the border increases by 1px
// NO THIS IS NOT THE RIGHT WAY TO GO ABOUT IT
// .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
//   top: 27px;
// }

mat-form-field.white {
  // color: white;

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
    --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
    --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
    --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
    --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);

    --mdc-outlined-text-field-caret-color: white;
    --mdc-outlined-text-field-focus-outline-color: white;
    --mdc-outlined-text-field-focus-label-text-color: rgba(255, 255, 255, 0.87);
    --mat-select-focused-arrow-color: rgba(255, 255, 255, 0.87);

    .mat-mdc-form-field-icon-prefix .mat-icon, .mat-mdc-form-field-icon-suffix .mat-icon {
      color: white
    }
  }
}

mat-form-field.no-outline {
  .mdc-text-field--outlined.mdc-text-field--disabled, .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    --mdc-outlined-text-field-outline-color: transparent;
    --mdc-outlined-text-field-hover-outline-color: transparent;
    --mdc-outlined-text-field-focus-outline-color: transparent;
  }
}
