@use '@angular/material' as mat;
@use 'sass:map';

@use '/node_modules/@angular/material/core/theming/palette' as Palette;



$royal-font: system-ui, "roboto", "sans-serif";

$royal-icon-color: rgba(0, 0, 0, 0.54);
//$royal-primary: #429ce0;
//$royal-accent: #008564;
$royal-primary: var(--primary-color);//#9776f9;
$royal-primary-light: #dcd0ff;
$royal-primary-lighter: #F0EAFE;
$royal-valid: #009c18;
$royal-warn: var(--warn-color);//#e74c3c;
$royal-warn-light: var(--warn-lighter-color);//#EF9A9A;
$royal-accent: var(--accent-color);//#6879f9;
$royal-dark-white: #FDFDFD;
$royal-light-grey: whitesmoke;
$royal-grey: #ececec;//var(--google-gray-700)
$royal-dark-grey: #b0b0b0;
$royal-dark: #2f2e41;
$royal-border-color: rgba(0, 0, 0, 0.12);

$royal-font-color: rgb(32, 33, 36); // #616161;
$royal-font-primary: #0D0D0D;
$royal-font-secondary: #606060;

$border-radius: 4px;

$ok-green:       #{map.get(mat.$green-palette,  500)};
$error-red:      #{map.get(mat.$red-palette,    500)};
$warning-orange: #{map.get(mat.$orange-palette, 500)};
$remark-blue:    #{map.get(mat.$blue-palette,   500)};
$note-blue:      #E7F2FA;

$disabled-text: Palette.$dark-disabled-text;

////
//// breakpoints
////
$break-small: 550px;
$break-medium: 750px;
$break-tablet: 768px;
$break-laptop: 1024px;
$break-1280: 1280px;
$break-laptop-L: 1440px;
$break-4k: 2560px;