@use '@angular/material' as mat;

@use 'abstract/themes/schoolsoft' as SCS;
@use 'abstract/themes/royal'      as RS;
@use 'abstract/themes/sdui'       as SDUI;
@use 'abstract/themes/additio'    as Additio;
@use 'abstract/themes/pupil'      as Pupil;
@use 'abstract/themes/konecto'    as Konecto;

@import '@fontsource/work-sans';
@import '@fontsource/roboto';
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';


// Include the common styles for Angular Material.
// Be sure that you only ever include this mixin once!
@include mat.core();

// all themes use the same typography and density, hence we only need to import them once and override the colors
@include mat.all-component-themes(RS.$theme);
.scs-theme {
  @include mat.all-component-colors(SCS.$theme);
}
.default-theme {
  // no need to redeclare the primary colors. As a matter of fact it will give rise to a compilation warning
  // @include mat.all-component-colors(RS.$theme);
}
.sdui-theme {
  @include mat.all-component-colors(SDUI.$theme);
}
.additio-theme {
  @include mat.all-component-colors(Additio.$theme);
}
.pupil-theme {
  @include mat.all-component-colors(Pupil.$theme);
}
.konecto-theme {
  @include mat.all-component-colors(Konecto.$theme);
}


////
//// more dense components
////
.dense-0 {
  @include mat.all-component-densities(-0);
}
.dense-1 {
  @include mat.all-component-densities(-1);
}
.dense-2 {
  @include mat.all-component-densities(-2);
}
.dense-3 {
  @include mat.all-component-densities(-3);

  // need to set the padding of icons manually
  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    &>.mat-icon {
      padding: 10px;
    }
  }
}
.dense-4 {
  // a value smaller then -3 is not supported for the "all-component-densities" mixin hence we need to set the densities manually
  // (https://github.com/angular/components/issues/26613#issuecomment-1439688797)
  @include mat.form-field-density(-4);
  @include mat.icon-density(-4);
  @include mat.icon-button-density(-4);
  @include mat.slider-density(-4);
  @include mat.button-toggle-density(-4);
  @include mat.chips-density(-4);

  // need to set the padding of icons manually
  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    &>.mat-icon {
      padding: 8px;
    }
  }
}
.dense-5 {
  @include mat.form-field-density(-5);
  @include mat.icon-density(-5);
  @include mat.icon-button-density(-5);
  @include mat.slider-density(-5);
  @include mat.button-toggle-density(-5);
  @include mat.chips-density(-5);

  // need to set the padding of icons manually
  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    &>.mat-icon {
      padding: 6px;
    }
  }
}
