
.full-height {
  height: 100% !important;
}

.full-width {
  width: 100% !important;
}

.no-max-width {
  max-width: none !important;
}

.no-max-height {
  max-height: none !important;
}

.no-min-height {
  min-height: 0 !important;
}

.no-min-width {
  min-width: 0 !important;
}

.no-height {
  height: 0 !important;
}

.no-width {
  width: 0 !important;
}

.inherit-height {
  height: inherit !important;
}

.inherit-width {
  width: inherit !important;
}

@for $i from 0 through 24 {
  .min-width-#{$i*2} {
    min-width: #{$i*2}px !important;
  }
  .min-height-#{$i*2} {
    min-height: #{$i*2}px !important;
  }
  .max-width-#{$i*2} {
    max-width: #{$i*2}px !important;
  }
  .max-height-#{$i*2} {
    max-height: #{$i*2}px !important;
  }
}