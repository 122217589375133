@use '../defaults.scss' as Defaults;

@mixin margin {
  .no-margin {
    margin: 0 !important;
  }

  .no-inline-margin {
    margin-inline: 0 !important;
  }

  .no-block-margin {
    margin-block: 0 !important;
  }

  .no-margin-top {
    margin-top: 0 !important;
  }

  .no-margin-bottom {
    margin-bottom: 0 !important;
  }

  .no-margin-left {
    margin-left: 0 !important;
  }

  .no-margin-right {
    margin-right: 0 !important;
  }

  .margin {
    margin: Defaults.$margin;
  }

  .margin-inline {
    margin-inline: Defaults.$margin;
  }

  .margin-block {
    margin-block: Defaults.$margin;
  }

  .margin-top {
    margin-top: Defaults.$margin;
  }

  .margin-bottom {
    margin-bottom: Defaults.$margin;
  }

  .margin-left {
    margin-left: Defaults.$margin;
  }

  .margin-right {
    margin-right: Defaults.$margin;
  }

  @for $i from 0 through 12 {
    .margin-#{$i*2} {
      margin: $i*2px;
    }
    .margin-inline-#{$i*2} {
      margin-inline: $i*2px;
    }
    .margin-block-#{$i*2} {
      margin-block: $i*2px;
    }
    .margin-top-#{$i*2} {
      margin-top: $i*2px;
    }
    .margin-bottom-#{$i*2} {
      margin-bottom: $i*2px;
    }
    .margin-left-#{$i*2} {
      margin-left: $i*2px;
    }
    .margin-right-#{$i*2} {
      margin-right: $i*2px;
    }
  }
}