$icon-color:      rgba(0, 0, 0, 0.54);

$primary:         var(--primary-color);
$primary-light:   #dcd0ff;
$primary-lighter: #F0EAFE;

$warn:            #e74c3c;
$warn-light:      #EF9A9A;

$valid:           #009c18;
$valid-light:     #00E022;

$accent:          var(--accent-color);

$grey:            #ececec;//var(--google-gray-700)
$dark-grey:       #b0b0b0;
$dark:            #2f2e41;

$gradient:         var(--gradient-color);

$font-color:      rgb(32, 33, 36); // #616161;
$font-primary:    #0D0D0D;
$font-secondary:  #606060;

$background-hover: #f5f5f5;

$color_overlap:                   #EF9A9A;
$color_doubleBooking:             #FFBD80;
$color_insufficientBookingMargin: #FFF176;
$color_insufficientBreakLength:   #FFF176;
$color_overtime:                  #E1BEE7;
$color_courseClustering:          #630069;
$color_unavailableDay:            #bababa;
$color_overflow:                  #bababa;
$color_missingDependency:         #C6A895;