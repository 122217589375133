@use '../colors.scss' as c;

.mat-mdc-button, .mdc-icon-button {
  &.white:not([disabled]) > .mat-icon {
    color: white;
  }
  &.white[disabled] > .mat-icon {
    color: rgba(255, 255, 255, 0.38);
  }

  &:not(.white,.mat-warn):not([disabled]) > .mat-icon {
    color: #{c.$icon-color};
  }
}

.mat-mdc-menu-item .mat-icon-no-color, .mat-mdc-menu-item .mat-mdc-menu-submenu-icon {
  --mat-menu-item-icon-color: #{c.$icon-color};
}

.mat-mdc-form-field-icon-prefix .mat-icon, .mat-mdc-form-field-icon-suffix .mat-icon {
  color: #{c.$icon-color};
}