@use '@angular/material' as mat;
@use 'index'             as shared;
@use 'typography'        as typography;

// Theme Config
.additio-theme {
  --primary-color: #005AC7;
  --primary-lighter-color: #83bbff;
  --primary-darker-color: #004395;
  --text-primary-color: #{shared.$light-primary-text};
  --text-primary-lighter-color: #{shared.$light-primary-text};
  --text-primary-darker-color: #{shared.$light-primary-text};
}
$mat-primary: (
  main: var(--primary-color),
  lighter: var(--primary-lighter-color),
  darker: var(--primary-darker-color),
  50: #94C4FF,
  100: #7AB6FF,
  200: #4D9DFF,
  300: #2486FF,
  400: #006EF5,
  500: #005AC7,
  600: #004EAD,
  700: #004599,
  800: #003980,
  900: #00306B,
  contrast: (
    main: shared.$light-primary-contrast,
    lighter: shared.$light-primary-contrast,
    darker: shared.$light-primary-contrast,
    50: shared.$light-primary-contrast,
    100: shared.$light-primary-contrast,
    200: shared.$light-primary-contrast,
    300: shared.$light-primary-contrast,
    400: shared.$light-primary-contrast,
    500: shared.$light-primary-contrast,
    600: shared.$light-primary-contrast,
    700: shared.$light-primary-contrast,
    800: shared.$light-primary-contrast,
    900: shared.$light-primary-contrast
  )
);
$theme-primary: mat.define-palette($mat-primary);

.additio-theme {
  --accent-color: #FF505A;
  --accent-lighter-color: #FF6169;
  --accent-darker-color: #EB000C;
  --text-accent-color: #{shared.$dark-accent-text};
  --text-accent-lighter-color: #{shared.$dark-accent-text};
  --text-accent-darker-color: #{shared.$dark-accent-text};
}
$mat-accent: (
  main: var(--accent-color),
  lighter: var(--accent-lighter-color),
  darker: var(--accent-darker-color),
  50: #FF9EA3,
  100: #FF999E,
  200: #FF858B,
  300: #FF757C,
  400: #FF6169,
  500: #FF505A,
  600: #FF1F2A,
  700: #EB000C,
  800: #B80009,
  900: #850007,
  contrast: (
    main: shared.$light-accent-contrast,
    lighter: shared.$light-accent-contrast,
    darker: shared.$light-accent-contrast,
    50: shared.$light-accent-contrast,
    100: shared.$light-accent-contrast,
    200: shared.$light-accent-contrast,
    300: shared.$light-accent-contrast,
    400: shared.$light-accent-contrast,
    500: shared.$light-accent-contrast,
    600: shared.$light-accent-contrast,
    700: shared.$light-accent-contrast,
    800: shared.$light-accent-contrast,
    900: shared.$light-accent-contrast
  )
);
$theme-accent: mat.define-palette($mat-accent);

.additio-theme {
  --gradient-color: #005AC7;
}

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent:  $theme-accent,
    warn:    shared.$warn-palette
  ),
  typography: typography.$typography,
  density: 0,
));