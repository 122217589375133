@use '../colors.scss' as c;
@use '../breakpoints.scss' as bp;

.mat-mdc-paginator {
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-color: #{c.$icon-color};

  // hide first and last page buttons if the screen is too small
  .mat-mdc-paginator-navigation-first, .mat-mdc-paginator-navigation-last {
    @media (max-width: #{bp.$break-small}) {
      display: none !important;
    }
  }
}