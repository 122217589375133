@use '@angular/material' as mat;
@use 'index'             as shared;
@use 'typography'        as typography;

// Theme Config
.pupil-theme {
  --primary-color: #009DDD;
  --primary-lighter-color: #1ABEFF;
  --primary-darker-color: #0080B3;
  --text-primary-color: #{shared.$light-primary-text};
  --text-primary-lighter-color: #{shared.$light-primary-text};
  --text-primary-darker-color: #{shared.$light-primary-text};
}
$mat-primary: (
  main: var(--primary-color),
  lighter: var(--primary-lighter-color),
  darker: var(--primary-darker-color),
  50: #E0F6FF,
  100: #C7EFFF,
  200: #8ADEFF,
  300: #52CEFF,
  400: #1ABEFF,
  500: #009DDD,
  600: #0080B3,
  700: #005F85,
  800: #003E57,
  900: #00212E,
  contrast: (
    main: shared.$light-primary-contrast,
    lighter: shared.$light-primary-contrast,
    darker: shared.$light-primary-contrast,
    50: shared.$light-primary-contrast,
    100: shared.$light-primary-contrast,
    200: shared.$light-primary-contrast,
    300: shared.$light-primary-contrast,
    400: shared.$light-primary-contrast,
    500: shared.$light-primary-contrast,
    600: shared.$light-primary-contrast,
    700: shared.$light-primary-contrast,
    800: shared.$light-primary-contrast,
    900: shared.$light-primary-contrast
  )
);
$theme-primary: mat.define-palette($mat-primary);

.pupil-theme {
  --accent-color: #009DDD;
  --accent-lighter-color: #1ABEFF;
  --accent-darker-color: #0080B3;
  --text-accent-color: #{shared.$dark-accent-text};
  --text-accent-lighter-color: #{shared.$dark-accent-text};
  --text-accent-darker-color: #{shared.$dark-accent-text};
}
$mat-accent: (
  main: var(--accent-color),
  lighter: var(--accent-lighter-color),
  darker: var(--accent-darker-color),
  50: #E0F6FF,
  100: #BDECFF,
  200: #7AD9FF,
  300: #38C7FF,
  400: #00AFF5,
  500: #0082B4,
  600: #00668F,
  700: #004D6B,
  800: #003347,
  900: #001A24,
  contrast: (
    main: shared.$light-accent-contrast,
    lighter: shared.$light-accent-contrast,
    darker: shared.$light-accent-contrast,
    50: shared.$light-accent-contrast,
    100: shared.$light-accent-contrast,
    200: shared.$light-accent-contrast,
    300: shared.$light-accent-contrast,
    400: shared.$light-accent-contrast,
    500: shared.$light-accent-contrast,
    600: shared.$light-accent-contrast,
    700: shared.$light-accent-contrast,
    800: shared.$light-accent-contrast,
    900: shared.$light-accent-contrast
  )
);
$theme-accent: mat.define-palette($mat-accent);

.pupil-theme {
  --gradient-color: #009DDD;
}

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent:  $theme-accent,
    warn:    shared.$warn-palette
  ),
  typography: typography.$typography,
  density: 0,
));