.mat-mdc-select-panel.no-vertical-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-select-panel.flex-option mat-option > span {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.mat-mdc-select-panel.flex-option.align-baseline mat-option > span {
  align-items: baseline;
}

.mat-mdc-select-panel.no-right-padding-option mat-option {
  padding-right: 0 !important;
}

.mat-mdc-select-panel.no-max-height {
  max-height: none !important;
}

// normally the select panel is the same width as the select, but this can cause problems when the select is narrow
.cdk-overlay-pane:has(.mat-mdc-select-panel.fit-content) {
  min-width: fit-content;
}

// all corners are rounded
.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  border-radius: 4px !important;
}
.mat-mdc-select-panel-above div.mat-mdc-select-panel {
  border-radius: 4px !important;
}