@use 'abstract'       as Global;
@use 'scrollbar.scss' as scrollbar;

@import 'material-icons/iconfont/material-icons.scss';
@import "~jsoneditor/dist/jsoneditor.min.css";

@import 'variables.scss';
@import 'theming.scss';
@import 'app/typography.scss';
@import 'app/core/push-notification/style.scss';

html, body {
  /**
  * Utility classes
  */
  @include Global.flex;
  @include Global.margin;
  @include Global.padding;

  font-family: $royal-font;
}

::-moz-selection {
  background: Global.$primary;
  color: white;
}

::selection {
  background: Global.$primary;
  color: white;
}

html, body {
  margin: 0px;
}

th {
  color: Global.$font-secondary;
  white-space: nowrap;
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 12px;
}

[hidden] {
  display: none !important;
}

.non-clickable {
  pointer-events: none;
}

.clickable {
  cursor: pointer !important;
}

.draggable {
  cursor: move !important;
}

.help {
  cursor: help !important;
}

.right {
  float: right;
}

.spacer {
  flex: 1 1 auto;
}

.uppercase {
  text-transform: uppercase;
}

.primary {
  color: Global.$primary !important;
}

.primary-bg {
  background: Global.$primary !important;
}

.accent {
  color: Global.$accent !important;
}

.accent-bg {
  background: Global.$accent !important;
}

.white {
  color: white !important;
}

.grey {
  color: Global.$grey !important;
}

.grey-bg {
  background: Global.$grey !important;
}

.white-bg {
  background: white !important;
}

.warn {
  color: Global.$warn-light !important;
}

.warn-bg {
  background: Global.$warn-light !important;
}
// an icon within a button should listen to the button's disabled state
// /* OLD */ .royal-icon:not(:disabled) {
// /* OLD */   color: Global.$icon-color;
// /* OLD */ }
mat-icon.royal-icon {
  color: Global.$icon-color;
}
button:disabled mat-icon.royal-icon, .disabled mat-icon.royal-icon, mat-icon.royal-icon.disabled {
  color: rgba(0, 0, 0, 0.26);   // this is the disabled color of the icon (preferably accessed as a variable...)
}

.royal-text-color {
  color: Global.$font-color;
}

.royal-text-primary {
  color: Global.$font-primary !important;
}

.royal-text-secondary {
  color: Global.$font-secondary !important;
}

.superscript {
  vertical-align: super;
  font-size: 9px;
  font-weight: 600;
}

.royal-font-highlight {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  border-radius: 10px;

  &:first-child {
    border-top: none;
  }
}

.royal-text-warn {
  color: Global.$warn;
}

.inline-text {
  white-space: nowrap;
}

.center-text {
  text-align: center;
}

.rs-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.table {
  width: 100%;
}

.no-padding-card {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.info-snackbar {
  color: black;
  background: whitesmoke;
}

.green-snackbar {
  background: #00ff00;
  color: black;
}

.warn-snackbar {
  background: $royal-warn;
}

.mat-mdc-menu-panel {
  max-width: 100vw !important;
}

// use safaris own scrollbar
body:not(.is-safari) .dark-scrollbar  {
  @include scrollbar.dark-scrollbar;
}
body.is-safari .dark-scrollbar  {
  overflow: auto !important;
}

// use safaris own scrollbar
body:not(.is-safari) .dark-vertical-scrollbar  {
  @include scrollbar.dark-scrollbar;
  overflow-x: hidden !important;
}
body.is-safari .dark-vertical-scrollbar  {
  overflow: auto !important;
  overflow-x: hidden !important;
}




.table-deleting-text {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  border-radius: 10px;
  color: white;
  position: absolute;
  z-index: 100;
  width: stretch;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-item-anim {
  display: block;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
}

.list-item-anim:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 100%;
  top: 0;
  background: Global.$primary;
  width: 4px;
  transition-property: bottom;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.list-item-anim:hover:before, .list-item-anim:focus:before, .list-item-anim:active:before {
  bottom: 0;
  right: 0px;
}

.mat-mdc-header-cell {
  // 600 does not look good in safari
  font-weight: 500 !important;
}

/*
  Dragula css
*/
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.new-line-tooltip {
  white-space: pre-line;
}
.left-align-tooltip {
  white-space: pre-line;
  .mdc-tooltip__surface {
    text-align: left;
  }
}

// button {
//   text-transform: uppercase;
//   letter-spacing: 1.25px;
// }

button.icon-with-text mat-icon {
    margin-left: -4px;
    margin-right: 8px;
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 18px;
    vertical-align: top;
}

.pointer-events-auto {
  pointer-events: auto;
}

mat-form-field.no-padding-bottom .mat-form-field-wrapper {
  padding-bottom: 0;
}

////
//// colors
////
.ok-green {
  color: $ok-green !important;
}

.error-red {
  color: $error-red !important;
}

.warning-orange {
  color: $warning-orange !important;
}

.remark-blue {
  color: $remark-blue !important;
}

.warn-color {
  color: var(--warn-color) !important
}

// if inside disabled button
button:disabled {
  .primary, .ok-green, .error-red, .warning-orange {
    color: rgba(0, 0, 0, 0.26) !important;

    .mat-badge-content {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
}


/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-options-sticky {
  position: sticky !important;
  z-index: 100;
  top: 0;
  background-color: white !important;
}

////
//// chips
////
span.chip-outer {
  display: inline-block;
  padding-left:  2px;
  padding-right: 2px;

  span.chip {
    background:    Global.$primary;
    border-radius: 4px;
    padding-left:  2px;
    padding-right: 2px;

    color: white;
  }
}
.mat-mdc-menu-panel.no-vertical-padding .mat-mdc-menu-content {
  padding-top:    0 !important;
  padding-bottom: 0 !important;
}

.main-toolbar {
  height: 64px !important; // otherwise it becomes 56px on small screens
}

////
////
////
.cdk-overlay-pane {
  &.full-width-dialog {
    max-width: 98vw !important;
  }
  &.position-relative .mat-mdc-dialog-container {
    position: relative !important;
  }
  &.no-padding .mat-mdc-dialog-container {
    padding: 0 !important;
  }
  &.overflow-hidden .mat-mdc-dialog-container {
    overflow: hidden !important;
  }
  &.stretch-vertically .mat-mdc-dialog-container {
    display: flex;
    align-items: stretch;
    overflow-x: hidden;

    // only the one related to the dialog
    > .ng-star-inserted {
      flex: 1;
    }
  }
  // DEPRECATED: risky to use, the dialog might not be able to scroll
  // &.overflow-visible {
    // .mat-mdc-dialog-container, .mat-mdc-dialog-content {
      // overflow: visible !important;
    // }
  // }
}

/*
  Rotating css class
*/
.rotating {
  @keyframes rotating {
    from { transform: rotate(0deg); }
    to   { transform: rotate(-360deg); }
  }
  animation: rotating 2s linear infinite;
}
.rotate90 {
  transform: rotate(90deg);
}

/*
  Expandable card
*/
$expand-amimation-duration-stage-1: 0.5s;
$expand-amimation-duration-stage-2: 0.25s;

.expanded-card {
  // height: 366px;
  box-sizing: border-box;
  position: absolute;
  z-index: 100;
  overflow-y: hidden;

  .close {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 100;
  }

  &.animate {
    animation-name: expand-vert, expand-horiz;
    animation-fill-mode: both;
    animation-delay: 0s, $expand-amimation-duration-stage-1;
    animation-duration: $expand-amimation-duration-stage-1, $expand-amimation-duration-stage-2;
  }
}

@keyframes expand-vert {
  0% {
    height: 366px;
  }
  100% {
    height: 100vh;
    top: 0px;
  }
}

@keyframes expand-horiz {
  0% {
  }
  100% {
    left: 0px;
    width: 100vw;
    border-radius: 0;
  }
}

// useful class to center things
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-justify-center {
  display: flex;
  justify-content: center;
}

/**
 * Utility classes
 */
